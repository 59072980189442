import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Seo from '../components/Seo'
import Layout from '../components/layout'
import MooseLottie from '../components/Footer/Moose/MooseLottie';
import Sidebar from '../components/Sidebar/Sidebar';
import PageHero from '../components/PageHero/PageHero'
import MobilePageHero from '../components/PageHero/MobilePageHero';
import Calendar from '../components/Calendar/Calendar';
import NewsCard from '../components/NewsCards/NewsCard';

import {
    Box,
    Heading,
    Flex
} from '@chakra-ui/react';

const CalendarPage = ({ data }) => {
    
    if (!data) return null; 
    const document = data.prismicCalendarPage.data;
    const backgroundImage = getImage(document.hero_image.localFile)
    const image = getImage(backgroundImage);
    
    const eventItem = data.allPrismicNews.edges;

    return (
        <Layout>
         <Seo title="City of Driggs Calendar of Events" />

    <PageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
        pageTitle={document.page_title.text}
    />
              <Box position="relative" zIndex="1">
      <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
        <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                // menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text}  
                // menuItems={menuItems}
                // pageLocation={pageLocation}
              />
           </Box>
           <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {/* {document.page_title.text} */}
            </Heading>
            <Calendar />
            <Box>
              <Flex flexWrap="wrap" mt="12">
                {eventItem.map((item, index) => {
                  return (
                    <NewsCard
                      image={getImage(item.node.data.featured_image.localFile)}
                      key={index}
                      title={item.node.data.post_title.text}
                      excerpt={item.node.data.post_excerpt.text}
                      url={item.node.url}
                      tags={item.node.tags}
                    />
                  )
                })}
              </Flex>
            </Box>

      </Box>
      <MooseLottie />
        </Flex>
      </Box>
      
    </Layout>
    )
}

export const query = graphql`
  {
    prismicCalendarPage {
      data {
        page_headline {
          text
        }
        hero_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
          alt
          copyright
        }
        subtitle {
          text
        }
        page_title {
          text
        }
        page_content {
          richText
        }
      }
    }
    allPrismicNews(
      sort: {fields: last_publication_date, order: DESC}
      filter: {tags: {eq: "Event"}}
    ) {
      edges {
        node {
          uid
          url
          data {
            post_title {
              text
            }
            post_excerpt {
              text
            }
            featured_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
          }
          tags
        }
      }
    }
  }
`

export default CalendarPage;
